import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout
    title={`Not found`}
    metaDescription={`Page not found`}
  >
    <h1>This page doesn't exist!</h1>
  </Layout>
)

export default NotFoundPage
